body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

}
@media screen and (max-width: 834px) {
  .wrapper {
    width: 90%;
    margin: 2em auto;
  }
  
  
  .col1 {
    width: 25%;
    float: left;
    background-color: #fff;
  }
  .col2 {
    width: 70%;
    float: right;
    background-color: #fff;
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;

}

a {
  text-decoration: none !important;
}

a :hover {
  text-decoration: none !important;
}

.cards-course {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.card-course-row {
  display: inline-block;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

}

.card-course-buttons {
  height: 250px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.card-course-buttons-show {
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  margin-top: 4px;
  width: 98%;
  margin-left: 1px;
}


::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}

::-webkit-scrollbar {
  width: 3px;
  background: #F4F4F4;
}

::-webkit-scrollbar-thumb {
  background: #ecebeb;
}

.card-course {
  background: #ffffff;
  width: 24em;
  border-radius: 0.6em;
  flex: 1 1 150px;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  padding: 0 !important;
  border-bottom: solid 5px #234286;
  flex-wrap: wrap;


}

.card-course:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card-course img {
  width: 100%;
  object-fit: cover;
}

.card-course h2 {
  color: #222 !important;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
  transition: all ease-in 100ms;

}

.card-course p {
  color: #777 !important;
}

.card-course h5 {
  color: #bbb !important;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
  align-items: center;
}

.card-body-course {
  padding: 0em;
  display: inline-block;
  flex-wrap: wrap;
}


.custom-btn {
  padding: 3px 3px 3px 3px;
  margin-left: 1px;
  border: 1px solid #000;
  font-family: 'Lato', sans-serif !important;
  font-weight: 1000;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border-radius: 5px;
  width: 330px;
  max-width: 98%;
  display: inline;

}



.btn-5 {
  background: #234286;
  color: #fff !important;
  line-height: 42px;
  padding: 5px;
  border: none;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.btn-5:hover {
  background: transparent;
  color: #000000 !important;
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  white-space: normal;
}

.btn-5:before,
.btn-5:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #234286;
  transition: 400ms ease all;
}

.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;

}


.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}



.carousel-container {
  display: inline-block;
  scroll-snap-type: x mandatory;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  background-color: #e6e6e6;
  border-radius: 1%;


}

.carousel-control-next,
.carousel-control-prev

/*, .carousel-indicators */
  {
  filter: invert(100%) !important;
}

.carousel-container ::-webkit-scrollbar {
  display: none;
}

.carousel-container>* {
  flex: 0 0 auto;
  scroll-snap-align: center;
  overflow: auto;
}


.carousel-item {
  flex: 0 0 auto;
  width: 100%;
  margin-right: 1rem;
}

.carousel-item:last-child {
  margin-right: 0;
}


@media (max-width: 1224px) {
  .course-additional-cards {
    justify-content: center !important;
  }
}

