@media (min-width: 1224px) {
  .app .app-body {
    max-width: 1224px;
    min-width: 1224px;
  }
}

@media (min-width: 600px) {
  .teste {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  body {
    background-color: red ;
  }
  .app {
    font-size: 0.85rem !important;
  }
  .app .logo-login, header .absences {
    display: none !important;
  }

  .hide-small-screen {
    display: none !important;
  }
  .hide-big-screen {
    display: block !important;
  }

  .splash-screen {
    margin-top: 12.5rem !important;
  }

  .apply-flex-column-on-small-devices {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.splash-screen {
  margin-top: 19rem;
}

.hide-small-screen {
  display: block;
}
.hide-big-screen {
  display: none;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.loading {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}
.loading p,
.loading div {
  color: transparent !important;
  background: linear-gradient(100deg, #DDD 30%, #eceff1 50%, #DDD 70%);
  background-size: 400%;
  animation: loading 2s ease-in-out infinite;
}
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

body {
  background-color: #F1F1F1;
}

.teste {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Verdana, Arial, Tahoma, Serif;
  font-size: 1rem;
  background-color: #F1F1F1;
}

.app > header {
  position:sticky;
  top: 0;
  left: 0;
  background-color: black;
  border-bottom: solid 1px #444;
  color:  white !important;
  -webkit-box-shadow: 1px 1px 5px 0px #444;
  box-shadow: 1px 1px 5px 0px #444;
  align-self: stretch;
  z-index: 9999;
}

.app > header main {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0 0;
}

.box-shadow {
  -webkit-box-shadow: 1px 1px 5px 0px #999999;
  box-shadow: 1px 1px 5px 0px #999999;
}

.app > header .group {
  align-items: center;
  display: flex;
}

.app .app-body {
  color:  #3A3A3A !important;
  background-color: #F1F1F1;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 0.5rem);
  padding: 0.25rem;
}

.app .app-body h1 {
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.app .app-body h2 {
  margin-top: 2rem;
  font-size: 1.2em;
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  color: #888 !important;
}

.card {
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 1px 5px 0px #999999;
  box-shadow: 1px 1px 5px 0px #999999;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: white;
  border-left: solid 3px #455701;
}

.card > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
}

.card > header:hover, .apply-hover:hover {
  background-color: #F1F1F1 !important;
  cursor: pointer !important;
  transition: 0.23s;
}

.apply-black-hover:hover {
  background-color: #0A0A0A !important;
  cursor: pointer !important;
  transition: 0.23s;
}

.apply-light-blue-hover:hover {
  background-color: #cbd7ee !important;
  cursor: pointer !important;
  transition: 0.23s;
}

.apply-light-gray-hover:hover {
  background-color: lightsteelblue !important;
  cursor: pointer !important;
  transition: 0.23s;
}

.card .card-body {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  border-top: solid 3px #F1F1F1;
}

.app .logo-idp {
  font-weight: bolder;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #F1F1F1;
  color: white !important;
  padding: 0.5rem;
}

.first-letter-up-case:first-letter {
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
}

table th, table td {
  border: solid 1px #DDD;
  padding: 0.75rem;
}

table.small-table th, table.small-table td {
  padding: 0.25rem;
}

table.no-border th, table.no-border td {
  border-color: transparent;
}

table th {
  text-align: left;
  font-weight: normal;
  color: #777 !important;
  font-size: 0.85rem;
}

a {
  color: inherit !important;
  text-decoration: none;
}

a.link, .link {
  color: #0a0a0a !important;
  text-decoration: underline;
  font-size: 0.9em;
}


li {
  padding: 0.3rem
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.idp-button {
  padding: 0.8rem 1rem !important;
  text-align: center !important;
  border-radius: 0.15rem !important;
  -webkit-box-shadow: 1px 1px 5px 0px #999999 !important;
  box-shadow: 1px 1px 5px 0px #999999 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  color: rgb(235, 231, 231) !important;
  letter-spacing: 0.05rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.75rem !important;
}



.idp-button:hover {
  -webkit-box-shadow: 1px 1px 15px 0px #999999 !important;
  box-shadow: 1px 1px 15px 0px #999999 !important;
}

.tab {
  display: flex;
  font-size: 0.85rem;
  margin-top: 1rem;
}

.tab-item {
  padding: 0.85rem;
  border-bottom: solid 2px #E0E0E0;
  cursor:  pointer;
}

.tab-item.selected {
  color:  #F1F1F1 !important;
  border-bottom-color: #F1F1F1;
}

.small-text {
  font-size: 0.85rem;
}

.large-text {
  font-size: 1.15rem !important;
}

.regular-text {
  font-size: 1rem;
}

.bold-text {
  font-weight: bold;
}

.light-text {
  color:  #777 !important;
}

.italic-text {
  font-style: italic;
}

.disable-children-mouseover-events {
  pointer-events: none;
}

.link-breadcrumb-item {
  border: solid 1px transparent;
  border-radius: 2px;
}

.link-breadcrumb-item:hover {
  border-color: #818181;
  cursor: pointer;
}

.bio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line-left {
  border-bottom: 1px solid black;
  width: 32em;
  height: 4px;
}

.line-right {
  border-bottom: 1px solid black;
  width: 32em;
  height: 4px;
}

.cord-name {
  position: relative;
}

.http-error-wrapper {
  margin-top: 2rem;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  background-color: #EEE;
  -webkit-box-shadow: 1px 1px 5px 0px #444;
  box-shadow: 1px 1px 5px 0px #444;
  border-radius: 3px;
}

.http-error-description {
  color: #555 !important;
  font-size: 1.2rem;
}

.http-error {
  color: #454545 !important;
  font-weight: bold;
  font-size: 4rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}
.apply-box-shadow {
  -webkit-box-shadow: 1px 1px 5px 0px #444;
  box-shadow: 1px 1px 5px 0px #444;
}

.apply-white-box-shadow {
  -webkit-box-shadow: 1px 1px 5px 0px #AAA;
  box-shadow: 1px 1px 5px 0px #666;
}


.custom-hr-line {
  width: 40%;
  height: 2px;
  background-color: black;
  margin: 0.5rem
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.8; }
}

.fade-in {
  animation: fadeIn 1s;
}

.folder-path-part {
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.topic-content img {
  display: block;
  margin: 0.5rem;
  max-width: 100%;
  max-height: 65vh;
}

/* width */
.scroll_width::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_track::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}
 
/* Handle */
.scroll_handle::-webkit-scrollbar-thumb {
  background: darkgray; 
  border-radius: 10px;
}

/* Handle on hover */
.scroll_hover::-webkit-scrollbar-thumb:hover {
  background: #808080; 
}
